// @ts-nocheck
import React, { useEffect, useState } from "react";
import { loadGapiInsideDOM } from 'gapi-script';
// import { gapi } from "gapi-script";
import { useLocation } from "react-router-dom";

import { useAuthentication } from "../../context/authentication";
import { useHistory } from "react-router-dom";
import { useFirebase } from "../../context/firebase";
import { TextInput, Pane, Button, toaster, Link } from 'evergreen-ui'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const RegisterWithEmail = () => {
  const { push } = useHistory();
  const { doLogout, user } = useAuthentication();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [userExists, setUserExists] = useState(false)
  
  let query = useQuery();

  const {
    auth,
    db,
  } = useFirebase();

  const signUp = () => {
    if (password.length < 5) {
      toaster.warning('エラーが発生しました', {
        description: 'パスワードは6文字以上で設定してください'
      })
    }
      auth.createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
          db.collection("users").doc(email).set({
            isEmailUser: true,
            registered: true,
          }).then(() => {
            // const user = userCredential.user;
            window.location.href = '/';
          })
        })
        .catch((error) => {
          auth.signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
              // const user = userCredential.user;
              db.collection("users").doc(email).set({
                isEmailUser: true,
                registered: true,
              }).then(() => {
                // const user = userCredential.user;
                window.location.href = '/';
              })
            }).catch(() => {
              toaster.danger('エラーが発生しました', {
                description: 'メールアドレスまたはパスワードが間違っています。'
              })
            })
        });
  }

  return (
      <div className="Wrap">
        <h3 style={{margin: 8}}>アカウント登録・ログイン</h3>
        <Link href="/register"
          style={{
            textDecoration: 'none',
            marginBottom: 32,
            display: 'flex',
            alignItems: 'center',
            color: '#777777',
            justifyContent: 'center',
            fontSize: 12,
          }}>
            Googleカレンダーをご利用の方
        </Link>
        <TextInput type="email" onChange={(e) => setEmail(e.target.value)} name="text-input-name" placeholder="メールアドレスを入力" />
        <br />
        <TextInput type="password" onChange={(e) => setPassword(e.target.value)} name="text-input-name" placeholder="パスワードを入力" />
        <br/>
        <Pane display="flex" marginX={20}>
          <Button onClick={() => signUp()}>
            新規登録
          </Button>
        </Pane>
      </div>
  );
};
