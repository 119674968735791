import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const AppHeader = () => {
  const location = useLocation();
  const [hidden, setHidden] = useState(false);
  useEffect(() => {
    setHidden(location.pathname === "/");
  }, [location]);

  if (hidden) return null;
  return (
    <div className="Header">
      <a href="/" style={{ textAlign: "center", width: 220 }}>
        <img style={{ height: 40, marginBottom: -6 }} src="/onechat_logo.png" />
        <p style={{ fontSize: 12, margin: 0, color: "#555555" }}>
          自動日程調整
        </p>
      </a>
    </div>
  );
};
