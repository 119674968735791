// @ts-nocheck
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import { gapi } from "gapi-script";
import { useLocalStorage } from "react-use";

import { useAuthentication } from "../../context/authentication";
import { useHistory } from "react-router-dom";
import { Button, Pane, majorScale, Link, toaster } from "evergreen-ui";
import { useFirebase } from "../../context/firebase";
import { useCookies } from "react-cookie";
import { UsageSlide } from "../../components/UsageSlide";

export const Home = () => {
  const { push } = useHistory();
  const { doLogout, user } = useAuthentication();
  const [isEmailUser, setIsEmailUser] = useState(true);
  const [staticZoomURL, setStaticZoomURL] = useState("");
  const [perissionMissing, setPerissionMissing] = useState(false);
  const [usageVisible, setUsageVisible] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["plan-redirect-url"]);

  const { db } = useFirebase();
  const logout = async () => {
    await doLogout();

    push("/login");
  };

  const checkGooglePermission = (firebaseUID: string) => {
    fetch(
      "https://timeshot-server.herokuapp.com/get_freebusy?firebaseUID=" +
        firebaseUID
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (Array.isArray(responseJson.busy)) {
          console.error("isArray.");
          const newEvents = [];
          responseJson.busy.map((item) => {
            newEvents.push(item);
          });
          console.error("newEvents");
          console.error(newEvents);
          return newEvents;
        } else {
          if (responseJson.message === "Insufficient Permission") {
            setPerissionMissing(true);
          }
        }
      });
  };

  const moveCredentials = async (user) => {
    const emailRef = db.collection("users").doc(user.email);
    await emailRef.get().then(async (tmpUser) => {
      const tmpUserData = tmpUser.data();
      if (tmpUserData.accessToken && tmpUserData.accessToken2) {
        await db
          .collection("users")
          .doc(user.uid)
          .set({
            accessToken: tmpUserData.accessToken,
            accessToken2: tmpUserData.accessToken2,
            name: tmpUserData.name,
            email: tmpUserData.email,
          })
          .then(async (user) => {
            await emailRef
              .update({
                accessToken: null,
                accessToken2: null,
                registered: true,
                isEmailUser: false,
              })
              .then((user) => {
                window.location.href = "/";
              });
          })
          .catch((e) => {
            console.error("e");
            console.error(e);
          });
      }
    });
  };

  const saveZoomURL = () => {
    db.collection("users").doc(user.uid).update({
      staticZoomURL,
    });

    toaster.success("予定の場所を設定しました！", {
      description: "Googleカレンダーの「場所」に表示されます",
    });
  };

  useEffect(() => {
    const urlCookie = cookies["plan-redirect-url"];
    if (urlCookie && urlCookie.indexOf("/link") > -1) {
      window.location.href = urlCookie;
    }

    if (user) {
      moveCredentials(user);
      const token = user.refreshToken;

      db.collection("users")
        .doc(user.uid)
        .get()
        .then(async (doc: any) => {
          if (doc.data()) {
            setStaticZoomURL(doc.data().staticZoomURL);
          }
        });

      db.collection("users")
        .doc(user.email)
        .get()
        .then(async (doc: any) => {
          setIsEmailUser(false);
          console.error(doc.data());
          if (doc.data().isEmailUser) {
            setIsEmailUser(true);
          } else {
            try {
              checkGooglePermission(user.uid);
            } catch (error) {
              alert(error);
            }
          }
        })
        .catch((error: any) => {
          // console.log("Error getting document:", error);
        });
    }
  }, []);

  const showCopiedToast = () => {
    toaster.success("クリップボードにリンクをコピーしました！", {
      description: "チャットやメールでリンクを共有しましょう。",
    });
  };

  if (perissionMissing) {
    return (
      <div className="Wrap">
        <h2 style={{ marginBottom: 0 }}>
          ⚠️ カレンダーの権限が許可されていません
        </h2>
        <p>画像のように、カレンダーの権限を許可して下さい。</p>
        <br />
        <div style={{ boxShadow: "1px 1px 4px rgba(0,0,0,.2)" }}>
          <img style={{ maxWidth: 400 }} src="/permission-guide.png" />
        </div>
        <br />

        <Pane display="flex" marginX={majorScale(2)}>
          <Link
            target="_blank"
            href="https://timeshot-server.herokuapp.com/install_gmail"
            style={{
              textDecoration: "none",
              padding: 12,
              border: "1px solid #ddd",
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
              color: "black",
              justifyContent: "center",
            }}
          >
            <img
              style={{ marginRight: 6, height: 20, width: 20 }}
              src="https://img.icons8.com/color/48/000000/google-logo.png"
            />
            再度認証する
          </Link>
        </Pane>
      </div>
    );
  }

  return (
    <>
      {!usageVisible ? (
        <div className="Header">
          <a href="/" style={{ textAlign: "center", width: 220 }}>
            <img
              style={{ height: 40, marginBottom: -6 }}
              src="/onechat_logo.png"
            />
            <p style={{ fontSize: 12, margin: 0, color: "#555555" }}>
              自動日程調整
            </p>
          </a>
        </div>
      ) : (
        <a onClick={() => setUsageVisible(false)}>
          <p
            style={{
              textAlign: "center",
              fontSize: 11,
              fontWeight: "bold",
              color: "#666666",
              marginTop: 12,
              marginBottom: 0,
            }}
          >
            ✖️ 使い方を閉じる
          </p>
          <br />
        </a>
      )}
      <div className="Wrap">
        {!isEmailUser && (
          <>
            {!usageVisible ? (
              <>
                {user?.email}としてログイン中
                <hr />
                <Pane display="flex" marginTop={majorScale(1)}>
                  <CopyToClipboard
                    text={
                      "https://schedule.onechat.jp/link/" +
                      user?.uid +
                      "/60 " +
                      "\nOneChatで作成"
                    }
                    onCopy={() => showCopiedToast()}
                  >
                    <Button
                      size="large"
                      intent="info"
                      appearance="primary"
                      className="commonButton"
                    >
                      <b>1時間の空き日程URLをコピー</b>
                    </Button>
                  </CopyToClipboard>
                </Pane>
                <Pane display="flex" marginTop={majorScale(1)}>
                  <CopyToClipboard
                    text={
                      "https://schedule.onechat.jp/link/" +
                      user?.uid +
                      "/30 " +
                      "\nOneChatで作成"
                    }
                    onCopy={() => showCopiedToast()}
                  >
                    <Button
                      size="large"
                      intent="success"
                      appearance="primary"
                      className="commonButton"
                    >
                      <b>30分の空き日程URLをコピー</b>
                    </Button>
                  </CopyToClipboard>
                </Pane>
                <br />
                <a onClick={() => setUsageVisible(true)}>
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "#666666",
                      marginTop: 0,
                      marginBottom: 4,
                    }}
                  >
                    使い方はこちら
                  </p>
                </a>
                <hr />
                <Pane display="flex" marginTop={majorScale(1)}>
                  <Button intent="danger" onClick={logout}>
                    ログアウト
                  </Button>
                </Pane>
              </>
            ) : (
              <div>
                <UsageSlide />
              </div>
            )}

            {/* <Pane marginTop={majorScale(1)}>
            <b style={{ fontSize: 11 }}>Zoom URL</b>
          </Pane>
          <Pane display="flex" marginTop={majorScale(1)}>
            <TextInput
              value={staticZoomURL}
              type="url"
              onChange={(e) => setStaticZoomURL(e.target.value)}
              name="text-input-name"
              placeholder="固定のZoom URLを入力"
            />
            <Button
              onClick={saveZoomURL}
              size="medium"
              intent="success"
              appearance="danger"
              style={{ marginLeft: 6 }}
            >
              <b>保存</b>
            </Button>
          </Pane> */}
          </>
        )}
      </div>
    </>
  );
};
