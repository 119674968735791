// @ts-nocheck
import React, { useEffect, useState } from "react";
import { loadGapiInsideDOM } from 'gapi-script';
// import { gapi } from "gapi-script";
import { useLocation } from "react-router-dom";

import { useAuthentication } from "../../context/authentication";
import { useHistory } from "react-router-dom";
import { useFirebase } from "../../context/firebase";
import { TextInput, Pane, Button, toaster } from 'evergreen-ui'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const AuthCallback = () => {
  const { push } = useHistory();
  const { doLogout, user } = useAuthentication();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [userExists, setUserExists] = useState(false)
  
  let query = useQuery();
  const queryEmail = query.get("email");

  const {
    auth,
    db,
  } = useFirebase();

  const logout = async () => {
    await doLogout();
    push("/login");
  };
  
  const loginAndMoveCredentials = async (userCredential) => {
    const emailRef = db.collection("users").doc(email);
    await emailRef.get().then(async (tmpUser) => {
      const tmpUserData = tmpUser.data();
      if (tmpUserData.accessToken && tmpUserData.accessToken2) {
        await db.collection("users").doc(userCredential.user.uid).set({
          accessToken: tmpUserData.accessToken,
          accessToken2: tmpUserData.accessToken2,
          name: tmpUserData.name,
          email: tmpUserData.email,
        }).then(async (user) => {
          await emailRef.update({
            accessToken: null,
            accessToken2: null,
            registered: true,
            isEmailUser: false,
            uid: userCredential.user.uid,
          }).then((user) => {
            window.location.href = '/';
          })
        }).catch((e) => {
          console.error('e')
          console.error(e)
        })
      } else {
        alert('エラーが発生しました')
      }
    });
  }

  const signUp = () => {
    if (password.length < 5) {
      toaster.warning('エラーが発生しました', {
        description: 'パスワードは6文字以上で設定してください'
      })
    }
    if (userExists) {
    auth.signInWithEmailAndPassword(email, password)
      .then(async (userCredential) => {
        await loginAndMoveCredentials(userCredential);
        // window.location.href = '/';
      }).catch(() => {
        toaster.danger('エラーが発生しました', {
          description: 'メールアドレスまたはパスワードが間違っています。'
        })
      })
    } else {
      auth.createUserWithEmailAndPassword(email, password)
        .then(async (userCredential) => {
          await loginAndMoveCredentials(userCredential);
        })
        .catch((error) => {
        });
    }
  }

  useEffect(() => {
    setEmail(queryEmail);
    db.collection("users").doc(queryEmail).get().then(async (doc: any) => {
        if (doc.exists) {
          const user = doc.data();
          if (user.registered) {
            setUserExists(true);
          }
        }
      }).catch((error: any) => {
        // console.log("Error getting document:", error);
      });
  }, []);

  useEffect(() => {
    // console.info(password)
  }, [password]);

  return (
      <div className="Wrap">
        <p>
          {userExists ? 'アカウントが存在します。パスワードを入力してください' : email + 'のアカウントのパスワードを設定してください'}          
        </p>
        <TextInput type="password" onChange={(e) => setPassword(e.target.value)} name="text-input-name" placeholder="パスワードを入力" />
        <br/>
        <Pane display="flex" marginX={20}>
          <Button onClick={() => signUp()}>
            {userExists ? 'ログイン' : '登録を完了'}
          </Button>
        </Pane>
      </div>
  );
};
