import { Pane, Link, majorScale } from "evergreen-ui";
import React, { useEffect, useState } from "react";
import { useAuthentication } from "../../context/authentication";
import { useFirebase } from "../../context/firebase";
import { Content } from "./Content";

const EVENT_DURTION_MINUTES = 30;

export const Link30 = () => {
  const [perissionMissing, setPerissionMissing] = useState(false)
  const { user } = useAuthentication();
  const { db } = useFirebase();

  const checkGooglePermission = (firebaseUID: string) => {
    fetch('https://timeshot-server.herokuapp.com/get_freebusy?firebaseUID=' + firebaseUID)
    .then((response) => response.json())
    .then((responseJson) => {
      if (Array.isArray(responseJson.busy)) {
        console.error('isArray.')
        const newEvents = [];
        responseJson.busy.map((item) => {
          newEvents.push(item);
        })
        console.error('newEvents')
        console.error(newEvents)
        return newEvents
      } else {
        if (responseJson.message === "Insufficient Permission") {
          setPerissionMissing(true)
        }
      }
    })
  }

  useEffect(() => {    
    if (user) {
      const token = user.refreshToken;
      db.collection("users").doc(user.email).get().then(async (doc: any) => {
        if (!doc.data().isEmailUser) {
            checkGooglePermission(user.uid);
        }
      }).catch((error: any) => {
        // console.log("Error getting document:", error);
      });
    }
  }, []);

  if (perissionMissing) {
        return (
          <div className="Wrap">
            <h2 style={{marginBottom: 0}}>⚠️ カレンダーの権限が許可されていません</h2>
            <p>画像のように、カレンダーの権限を許可して下さい。</p>
            <br/>
            <div style={{ boxShadow: "1px 1px 4px rgba(0,0,0,.2)" }}>
              <img style={{ maxWidth: 400 }} src="/permission-guide.png" />
            </div>
            <br/>
    
            <Pane display="flex" marginX={majorScale(2)}>
                <Link target="_blank" href="https://timeshot-server.herokuapp.com/install_gmail"
                style={{
                  textDecoration: 'none',
                  padding: 12,
                  border: '1px solid #ddd',
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  color: 'black',
                  justifyContent: 'center'
                }}>
                  <img style={{marginRight: 6, height: 20, width: 20}} src="https://img.icons8.com/color/48/000000/google-logo.png"/>
                  再度認証する
                </Link>
              </Pane>
          </div>
        )
      }
    return <Content baseUnit={EVENT_DURTION_MINUTES} />}
;