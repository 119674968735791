import React from "react";

export const AppFooter = () => {
  return null;
  return (
    <div className="Footer">
      <div className="Footer_col">
        <p>Copyright © 2021 timeshot. All Rights Reserved.</p>
      </div>
      <div className="Footer_col">
        <a href="https://timeshot.me/contact/">
          <p>お問合せ</p>
        </a>
        <a href="https://timeshot.me/privacy-policy/">
          <p>プライバシーポリシー</p>
        </a>
        <a href="https://timeshot.me/terms/">
          <p>利用規約</p>
        </a>
      </div>
    </div>
  );
};
