import "./style.scss";
import React, { useState } from "react";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";
import { Home } from "./pages/Home";
import { Link30 } from "./pages/Link/30";
import { Link60 } from "./pages/Link/60";
import { RegisterWithEmail } from "./pages/RegisterWithEmail";
import { AppHeader } from "./components/AppHeader";

import { Login } from "./pages/Login";
import { AuthCallback } from "./pages/AuthCallback";

import { Register } from "./pages/Register";
import { useAuthentication } from "./context/authentication";
import { AppFooter } from "./components/AppFooter";

export const App = () => {
  const { isFetchingUser, isLogged } = useAuthentication();

  if (!isLogged && isFetchingUser) return null;

  return isLogged &&
    window.location.pathname !== "/callback" &&
    window.location.pathname !== "/register_email" ? (
    <AuthenticatedApp />
  ) : (
    <UnauthenticatedApp />
  );
};

const AuthenticatedApp = () => {
  const { isLogged } = useAuthentication();

  if (!isLogged) return <Redirect to="/register" />;

  return (
    <>
      <AppHeader />
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route exact path={["/", "/login"]}>
          <Redirect to="/" />
        </Route>
        <Route exact path="/register">
          <Register />
        </Route>
        <Route exact path="/link/*/60">
          <Link60 />
        </Route>
        <Route exact path="/link/*/30">
          <Link30 />
        </Route>
        <Route exact path="/slots/30">
          <Link30 />
        </Route>
        <Route exact path="/slots/60">
          <Link60 />
        </Route>
        <Route path="*">
          <div className="Wrap">
            <p>Loading...</p>
          </div>
        </Route>
      </Switch>
      <AppFooter />
    </>
  );
};

const UnauthenticatedApp = () => {
  return (
    <Switch>
      <Route exact path={["/", "/login"]}>
        <Register />
      </Route>
      <Route exact path="/register">
        <Register />
      </Route>
      <Route exact path="/register_email">
        <RegisterWithEmail />
      </Route>
      <Route exact path="/link/*/60">
        <Link60 />
      </Route>
      <Route exact path="/link/*/30">
        <Link30 />
      </Route>
      <Route exact path="/slots/30">
        <Link30 />
      </Route>
      <Route exact path="/slots/60">
        <Link60 />
      </Route>
      <Route exact path="/callback">
        <AuthCallback />
      </Route>
    </Switch>
  );
};
