import React, { useEffect, useState } from "react";
import { Pane, majorScale, Link, Button, Dialog, Alert } from "evergreen-ui";
import { useAuthentication } from "../../context/authentication";
import { useLocation, useHistory } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const Register: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { doLogout, user } = useAuthentication();
  const query = useQuery();
  const history = useHistory();
  const from = query.get("from");
  const platfromType = query.get("platfrom_type");
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    if (user) {
      window.location.href = "/";
    }
  }, []);

  return (
    <form>
      <div className="Wrap">
        <br />
        <img style={{ maxWidth: 240, width: "100%" }} src="/onechat_logo.png" />
        <p style={{ marginTop: 0 }}>日程調整</p>
        <br />
        <br />
        <Pane display="flex" marginX={majorScale(2)}>
          <Link
            target={platfromType === "webview" ? "_self" : "_blank"}
            onClick={() => setIsShown(true)}
            style={{
              textDecoration: "none",
              padding: 12,
              border: "1px solid #ddd",
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
              color: "black",
              justifyContent: "center",
            }}
          >
            <img
              style={{ marginRight: 6, height: 20, width: 20 }}
              src="https://img.icons8.com/color/48/000000/google-logo.png"
            />
            {isSubmitting ? "連携中..." : "Googleカレンダーと連携"}
          </Link>
        </Pane>
        {from && from === "link" && (
          <Link
            href="/register_email"
            style={{
              textDecoration: "none",
              margin: 12,
              display: "flex",
              alignItems: "center",
              color: "#777777",
              justifyContent: "center",
              fontSize: 12,
            }}
          >
            Googleアカウントをお持ちでない方
          </Link>
        )}
        <br />
        <p style={{ fontSize: 14 }}>
          <Link
            target={platfromType === "webview" ? "_self" : "_blank"}
            href="https://timeshot.me/terms/"
          >
            利用規約
          </Link>
          、
          <Link
            target={platfromType === "webview" ? "_self" : "_blank"}
            href="https://timeshot.me/privacy-policy/"
          >
            プライバシーポリシー
          </Link>
          に同意したうえで
          <br />
          ログインしてください。
        </p>
      </div>
      <Dialog
        isShown={isShown}
        title="認証画面で、すべてにチェックを入れてください"
        onCloseComplete={() => setIsShown(false)}
        confirmLabel="登録に進む"
        cancelLabel="キャンセル"
        hasFooter={false}
      >
        <img
          style={{ height: 300, width: "100%", objectFit: "contain" }}
          src="/auth-guide.png"
        />
        <Alert
          intent="none"
          title="自動的に空き時間をマッチし、日程調整が完了した際にカレンダーに追加するため、権限を必要としています。"
          marginBottom={32}
        />
        <Link href="https://timeshot-server.herokuapp.com/install_gmail">
          <Button
            style={{
              width: "100%",
              backgroundColor: "#4eb989",
              borderColor: "#4eb989",
              color: "white",
              fontWeight: "bold",
              fontSize: 16,
              marginBottom: 20,
              height: 40,
            }}
          >
            確認して登録に進む
          </Button>
        </Link>
      </Dialog>
    </form>
  );
};
