import React, { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";

// import * as dayjs from "dayjs";
import { useAuthentication } from "../../context/authentication";
import { useParams, useHistory } from "react-router-dom";
import { useFirebase } from "../../context/firebase";
import { Button, Link, toaster } from "evergreen-ui";
import { CopyToClipboard } from "react-copy-to-clipboard";

import "dayjs/locale/ja";
import { useCookies } from "react-cookie";

var dayjs = require("dayjs");
var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.extend(isBetween);
dayjs.tz.setDefault("Asia/Tokyo");
dayjs.locale("ja");

interface UserInfo {
  displayName: string | null;
  name: string | null;
  email: string | null;
  phoneNumber: string | null;
  photoURL: string | null;
  providerId: string;
  /**
   * The user's unique ID.
   */
  uid: string;
}

type Props = {
  baseUnit: number;
};

export const Content: React.FC<Props> = (props) => {
  const EVENT_DURTION_MINUTES = props.baseUnit;
  const { push } = useHistory();
  const params = useParams();
  const { doLogout, user } = useAuthentication();
  const [targetUser, setTargetUser] = useState<UserInfo | null>(null);
  const [me, setMe] = useState<UserInfo | null>(null);
  const [myEvents, setMyEvents] = useState([]);
  const [targetUserEvents, setTargetUserEvents] = useState([]);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [threeSecondsPassed, setThreeSecondsPassed] = useState(false);
  const [myEventsLoading, setMyEventsLoading] = useState(true);
  const [targetUserEventsLoading, setTargetUserEventsLoading] = useState(true);
  const [allSlotsVisible, setAllSlotsVisible] = useState(false);
  const [redirectURL, setRedirectURL, remove] = useLocalStorage(
    "redirect-url",
    ""
  );
  const [cookies, setCookie, removeCookie] = useCookies(["plan-redirect-url"]);
  const [completed, setCompleted] = useState(false);
  const paramsUserID = params[0];

  const [selectedSlot, setSelectedSlot] = useState<any | null>(null);

  const { db, analytics } = useFirebase();

  const getCalendatEvents = async (firebaseUID: string): Promise<any[]> => {
    return fetch(
      "https://timeshot-server.herokuapp.com/get_freebusy?firebaseUID=" +
        firebaseUID
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (Array.isArray(responseJson.busy)) {
          console.error("isArray.");
          const newEvents = [];
          console.table(responseJson);
          responseJson.busy.map((item) => {
            newEvents.push(item);
          });
          console.error("newEvents");
          console.error(newEvents);
          return newEvents;
        } else {
          return [];
          // alert('取得中にエラーが発生しました。再読み込みします...')
          // window.location.reload();
        }
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  };

  useEffect(() => {
    if (user && user.email) {
      removeCookie("plan-redirect-url", { path: "/" });
      getTargetUserEvents();
      var myUIDRef = db.collection("users").doc(user.uid);
      myUIDRef
        .get()
        .then(async (doc: any) => {
          setMe(doc.data());
          console.warn(doc.data());
        })
        .catch((error: any) => {
          console.error("Error getting document:", error);
        });
      db.collection("users")
        .doc(user.email)
        .get()
        .then(async (doc: any) => {
          if (doc.data().isEmailUser) {
            setMyEventsLoading(false);
          } else {
            getMyEvents(user);
          }
        })
        .catch((error: any) => {
          console.error("Error getting document:", error);
        });
    } else {
      setTimeout(() => {
        setCookie("plan-redirect-url", window.location.href, { path: "/" });
        push("/login?from=link");
      }, 1000);
    }
    setTimeout(() => {
      setThreeSecondsPassed(true);
    }, 3000);
  }, []);

  const getMyEvents = async (user) => {
    try {
      const events = await getCalendatEvents(user.uid);
      if (events === null) {
        const events = await getCalendatEvents(user.uid);
        if (events !== null) {
          setMyEventsLoading(false);
          setMyEvents(events);
        }
      } else {
        setMyEventsLoading(false);
        setMyEvents(events);
      }
      console.log("my events");
      console.log(events);
    } catch (error) {
      alert(error);
    }
  };

  const getTargetUserEvents = async () => {
    let targetUserUID = paramsUserID;
    if (!targetUserUID && user) {
      targetUserUID = user.uid;
    }
    var targetUserUIDRef = db.collection("users").doc(targetUserUID);
    // uidからターゲットユーザーを取得
    targetUserUIDRef
      .get()
      .then(async (doc: any) => {
        setTargetUser(doc.data());
        console.warn(doc.data());
      })
      .catch((error: any) => {
        console.error("Error getting document:", error);
      });
    if (user.uid === targetUserUID) {
      setAllSlotsVisible(true);
      // alert("自分の調整リンクは利用できません");
      // return;
    }
    const events = await getCalendatEvents(targetUserUID);
    if (events === null) {
      const events = await getCalendatEvents(targetUserUID);
      if (events !== null) {
        setTargetUserEventsLoading(false);
        setTargetUserEvents(events);
      }
    } else {
      setTargetUserEventsLoading(false);
      setTargetUserEvents(events);
    }
  };

  useEffect(() => {
    if (!targetUserEventsLoading && !myEventsLoading) {
      checkAvailableSlots();
    }
  }, [targetUserEvents, myEvents]);

  const handleConfirm = async () => {
    const targetUserUID = params[0];
    const moment = require("moment-timezone");
    moment().tz("Asia/Tokyo").format();

    const startDateTime = moment(Number(selectedSlot)).format(
      "YYYY-MM-DDTHH:mm:00"
    );
    const endDateTime = moment(Number(selectedSlot))
      .add("minutes", EVENT_DURTION_MINUTES)
      .format("YYYY-MM-DDTHH:mm:00");
    const myName = me ? me.name : user.email;

    const data = {
      title: targetUser.name + "と" + myName + "さんの予定",
      creatorEmail: targetUser.email,
      creatorFirebaseUid: targetUserUID,
      targetUserEmail: user.email,
      startDateTime,
      endDateTime,
    };

    // const url = 'http://0.0.0.0:64240/set_event';
    const url = "https://timeshot-server.herokuapp.com/set_event";

    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        const event = responseJson;
        if (event && event.kind) {
          setCompleted(true);
          analytics.logEvent("select_content", {
            content_type: "image",
            content_id: "P12453",
            items: [{ name: "Kittens" }],
          });

          analytics.logEvent("adjustment_completed", {
            senderEmail: targetUser.email,
            recipientEmail: user.email,
          });

          setTimeout(() => {
            window.location.href = "/";
          }, 5000);
        } else {
          alert("日程調整中にエラーが発生しました。");
        }
      })
      .catch((error) => {
        console.error(error);
        return [];
      });
  };

  const checkAvailableSlots = () => {
    console.error("checkAvailableSlots");
    const newSlots = [];
    let initialDate = dayjs().set("minute", 0).set("second", 0);
    const businessStartHour = 9;
    const businessEndHour = 16;
    const weeklySlotCount =
      (businessEndHour - businessStartHour) * 7 * (60 / props.baseUnit);
    console.info("weeklySlotCount");
    console.info(weeklySlotCount);

    [...Array(weeklySlotCount * 4)].map((_, i) => {
      if (
        Number(initialDate.format("HH")) > businessEndHour ||
        initialDate.format("HH:MM") === "18:00"
      ) {
        const diff = (businessStartHour + 24 - businessEndHour) * 60;
        initialDate = initialDate.set("hours", 18).add(diff, "minutes");
      } else {
        initialDate = initialDate.add(EVENT_DURTION_MINUTES, "minutes");
      }
      let isAvailable = true;
      const targetDateTime = initialDate;
      const targetEndDateTime = targetDateTime.add(
        EVENT_DURTION_MINUTES,
        "minutes"
      );
      const isBusinessTime =
        targetDateTime.day() !== 0 && targetDateTime.day() !== 6;

      myEvents.map((event) => {
        const planStartDateTime = dayjs(event.start);
        const planEndDateTime = dayjs(event.end);

        const hasPlan =
          targetDateTime.isBetween(planStartDateTime, planEndDateTime) ||
          planStartDateTime
            .add(1, "minutes")
            .isBetween(targetDateTime, targetEndDateTime);

        if (hasPlan || targetDateTime.hour() < 9) {
          isAvailable = false;
        }
      });

      targetUserEvents.map((event) => {
        const planStartDateTime = dayjs(event.start);
        const planEndDateTime = dayjs(event.end);

        const hasPlan = targetDateTime.isBetween(
          planStartDateTime,
          planEndDateTime
        );

        if (hasPlan) {
          isAvailable = false;
        }
      });

      if (isBusinessTime && isAvailable) {
        newSlots.push(targetDateTime);
      }
    });

    setAvailableSlots(newSlots);
    console.info("newSlots");
    console.info(newSlots);
    setSelectedSlot(newSlots[0]);
  };

  const showCopiedToast = () => {
    toaster.success("クリップボードにリンクをコピーしました！", {
      description: "チャットやメールでリンクを共有しましょう。",
    });
  };

  if (myEventsLoading || targetUserEventsLoading || !threeSecondsPassed) {
    return (
      <div className="Wrap">
        <div style={{ textAlign: "center" }}>
          <iframe
            className="lottieFrame"
            src="https://embed.lottiefiles.com/animation/2436"
          ></iframe>
          {paramsUserID ? (
            <h3>予定を自動調整中...</h3>
          ) : (
            <h3>空き日程を取得中...</h3>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="FlexCenter LinkContent">
      <div style={{ maxWidth: 440, width: "100%" }}>
        {completed ? (
          <>
            <h2 style={{ textAlign: "center" }}>🎉 日程が確定されました!</h2>
            <div
              className={
                allSlotsVisible
                  ? "AvailableSlotList expanded"
                  : "AvailableSlotList"
              }
            >
              <div className="Slot selected">
                {selectedSlot.format("YYYY年M月D日（ddd） HH:mm")} {" - "}
                {selectedSlot
                  .add(EVENT_DURTION_MINUTES, "minutes")
                  .format("HH:mm")}
              </div>
            </div>
            <p style={{ textAlign: "center" }}>
              あなたと参加者のカレンダーに追加されました。
              <br />
              参加者に確定通知のメールを配信しました。
            </p>
          </>
        ) : (
          <>
            <h2 style={{ textAlign: "center" }}>
              {targetUser.email} <br />
              の空き日程（
              {EVENT_DURTION_MINUTES === 30 ? "30分" : "1時間"}）はこちらです
            </h2>
            <div
              className={
                allSlotsVisible
                  ? "AvailableSlotList expanded"
                  : "AvailableSlotList"
              }
            >
              {allSlotsVisible || !selectedSlot ? (
                <>
                  {availableSlots.map((slot) => (
                    <a onClick={() => setSelectedSlot(slot)}>
                      <div
                        key={JSON.stringify(slot)}
                        className={
                          selectedSlot === slot ? "Slot selected" : "Slot"
                        }
                      >
                        {slot.format("M月D日（ddd） HH:mm")} {" - "}
                        {slot
                          .add(EVENT_DURTION_MINUTES, "minutes")
                          .format("HH:mm")}
                      </div>
                    </a>
                  ))}
                </>
              ) : (
                <a onClick={() => setSelectedSlot(selectedSlot)}>
                  <div
                    key={JSON.stringify(selectedSlot)}
                    className={"Slot selected"}
                  >
                    {selectedSlot.format("M月D日（ddd） HH:mm")} {" - "}
                    {selectedSlot
                      .add(EVENT_DURTION_MINUTES, "minutes")
                      .format("HH:mm")}
                  </div>
                </a>
              )}
            </div>
            <br />
            <div className="FlexCenter">
              {paramsUserID ? (
                <Button
                  onClick={handleConfirm}
                  size="large"
                  intent="info"
                  appearance="primary"
                  className="themeButton"
                >
                  <b>日程を確定する</b>
                </Button>
              ) : (
                <CopyToClipboard
                  text={
                    "https://schedule.onechat.jp/link/" +
                    user?.uid +
                    "/" +
                    EVENT_DURTION_MINUTES
                  }
                  onCopy={() => showCopiedToast()}
                >
                  <Button
                    onClick={showCopiedToast}
                    size="large"
                    intent="info"
                    appearance="primary"
                    className="themeButton"
                  >
                    <b>空き日程URLをコピー</b>
                  </Button>
                </CopyToClipboard>
              )}
            </div>
            <br />
            <div className="FlexCenter">
              <Link
                style={{ color: "#888888", fontWeight: "bold" }}
                onClick={() => setAllSlotsVisible(!allSlotsVisible)}
              >
                {allSlotsVisible ? "閉じる" : "別の日程を見る"}
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
